import { losseContentParse, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { LoaderData } from '@ubo/losse-sjedel'
import Content from '../elements/Content'
import type { Component_Footer } from '~/graphql/types'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()
  const { page } = useLoaderData<LoaderData>()

  return (
    <footer className="bg-black text-white">
      <div className="container pt-20 pb-10">
        <div className="lg:grid grid-cols-5">
          <div className="lg:col-span-2">
            <div className="grid grid-cols-4">
              <div className="col-span-2">
                <Content className="font-hh-heading children-p:mb-5 children-a:font-light">{footer?.information?.contact}</Content>

                <div className="mt-5">
                  <div className="font-hh-heading uppercase font-bold">{footer?.information?.socials?.title}</div>

                  <div className="flex items-center mt-3">
                    {footer?.information?.socials?.items?.map((item, index) => (
                      <LosseLink
                        key={index}
                        to={item?.link?.url}
                        target={item?.link?.target as string}
                        className="text-[0px] w-7 h-7 mr-2 rounded-full overflow-hidden focus:ring-offset-black focus:ring-hh-blue focus:ring-2 focus:ring-offset-2"
                      >
                        <LossePlaatjie
                          maxwidth={50}
                          src={item?.icon}
                          className="object-cover w-full h-full filter-gray hover:filter-blue"
                        />
                        {item?.link?.title}
                      </LosseLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <LosseLink src={footer?.information?.support} className="button-blue focus:ring-offset-black" />
              </div>
            </div>
          </div>
          <div className="col-span-3 mt-10 lg:mt-0">
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-5">
              {footer?.menus?.map((item, index) => (
                <div key={index} className="col-span-1">
                  <h2 className="font-hh-heading font-bold text-[16px] uppercase">{item?.title}</h2>

                  <div className="mt-5 font-hh-paragraph">
                    {item?.items?.map((item, index) => (
                      <LosseLink
                        key={index}
                        to={item?.link?.url}
                        target={item?.link?.target as string}
                        className="mb-3 text-sm opacity-75 font-light hover:underline focus:opacity-50 block"
                      >
                        {losseContentParse(item?.link?.title)}
                      </LosseLink>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-20 relative lg:pb-14">
          <div className="relative z-10 bg-black pr-5 lg:pr-10 py-2 inline-block">
            <div className="sm:flex items-center font-hh-paragraph">
              {footer?.policies?.map((item, index) => (
                <LosseLink
                  key={index}
                  src={item?.link}
                  className="mr-5 text-sm max-sm:mt-2 font-light block hover:underline focus:opacity-50"
                />
              ))}
              {page?.databaseId === 1116 && (
                <>
                  <a
                    href="https://wpadmin.heinenhopman.com/whistleblowing-percorso-di-segnalazioni/"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-5 text-sm max-sm:mt-2 font-light block hover:underline focus:opacity-50"
                  >Whisteblowing</a>
                </>
              )}
            </div>
          </div>
          <div className="absolute max-sm:hidden z-0 h-[1px] bg-white w-full inset-x-0 top-1/2 transform -translate-y-[50%]" />
        </div>
      </div>
    </footer>
  )
}
