import { LossePlaatjie } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import Content from '~/components/elements/Content'
import type { DynamicMenuEdges } from './HeaderDynamic'

interface DynamicPreviewProps {
  items?: DynamicMenuEdges
  menu: {
    current: number
    submenu: number
  }
}

export default function DynamicPreview({ items, menu }: DynamicPreviewProps) {
  if (!items || Object.keys(items).length === 0 || !items[menu.submenu]) {
    return null
  }

  return (
    <AnimatePresence>
      <motion.div
        key={`dynamic-preview-${menu.submenu}`}
        id={`dynamic-preview-${menu.submenu}`}
        className="absolute h-full w-full left-0 top-0 overflow-hidden"
        initial={{ y: '-100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.5 }}
      >
        <div className="absolute z-20 bottom-0 left-0 w-full p-10 xl:p-14">
          <h2 className="text-white font-hh-heading font-bold text-3xl xl:text-4xl uppercase pb-8">{items[menu.submenu].node.title}</h2>
          <Content className="text-white tracking-tight leading-7 lg:text-lg font-hh-form">
            {items[menu.submenu].node.recap?.description}
          </Content>
        </div>
        <div className="bg-hh-mine-shaft z-10 bg-opacity-50 absolute h-full w-full top-0 left-0" />
        <LossePlaatjie maxwidth={1200} className="h-full w-full object-cover" src={items[menu.submenu].node.recap?.image} alt="" />
      </motion.div>
    </AnimatePresence>
  )
}
