/* eslint-disable react-hooks/exhaustive-deps */
import { losseContentParse, LosseLink, useLoaderData, useLocation, useLosseData, useNavigation } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useLosseLayout } from '@ubo/losse-sjedel'
import DynamicPreview from './DynamicPreview'
import DynamicMenu from './DynamicMenu'
import DynamicSubmenu from './DynamicSubmenu'
import type { HeaderLoaderData } from '../Header'
import type { Acf_Link, Category, Component_Header_Dynamicmenu, Market, Maybe, Product, Service } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Message from '~/components/elements/Message'
import SearchCatalog from '~/components/layout/Header/Dynamic/SearchCatalog'
import clsx from 'clsx'
import DynamicSubmenuProducts from './DynamicSubmenuProducts'

type Menu = { current: number; submenu: number }

export default function HeaderDynamic() {
  const { setScrollable, isScrollable } = useLosseLayout()
  const navigation = useNavigation()
  const location = useLocation()
  const { header } = useLoaderData<HeaderLoaderData>()
  const [menu, setMenu] = useState<Menu>({
    current: -1,
    submenu: 0
  })
  const isOpen = menu.current !== -1
  const dynamicMenu = header.dynamicmenu![menu.current] || undefined

  useEffect(() => {
    if (navigation.state === 'loading') return
    if (navigation.state === 'idle') {
      setMenu({ ...menu, current: -1 })
    }
  }, [navigation.state])

  function handleKeydown(event: KeyboardEvent) {
    if (isOpen && event.key === 'Escape') {
      setMenu({ ...menu, current: -1 })
    }
  }

  function reset() {
    if (menu.current !== -1) {
      setMenu({
        current: -1,
        submenu: 0
      })
    }
  }

  useEffect(() => {
    reset()
  }, [location.pathname])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    if (isOpen && isScrollable) {
      setScrollable(false)
    } else if (!isOpen && !isScrollable) {
      setScrollable(true)
    }

    if (!isOpen) {
      reset()
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen])

  return (
    <>
      <div className="hidden xl:flex lg:flex-row flex-col gap-8 lg:gap-32">
        {header.dynamicmenu?.map((l, index: number) => {

          if (l?.style === 'link_only') {
            return (
              <LosseLink
                key={index}
                className="relative font-hh-heading cursor-pointer uppercase text-[22px] lg:text-base flex items-center gap-1 font-bold text-white py-1 px-4 rounded-3xl hover:bg-hh-blue button-base-ring focus:ring-offset-black focus:ring-hh-blue"
                to={l?.link?.url}
              >
                {losseContentParse(l?.link?.title)}
              </LosseLink>
            )
          }

          return (
            <div
              onClick={() => setMenu({ ...menu, current: index })}
              key={index}
              className="relative font-hh-heading cursor-pointer uppercase text-[22px] lg:text-base flex items-center gap-1 font-bold text-white py-1 px-4 rounded-3xl hover:bg-hh-blue button-base-ring focus:ring-offset-black focus:ring-hh-blue"
            >
              {losseContentParse(l?.link?.title)}
              <svg
                className="lg:hidden block absolute top-0 -bottom-1 my-auto right-0"
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 5.66658C6.00037 5.88846 5.95805 6.10823 5.87545 6.31325C5.79286 6.51827 5.67162 6.70451 5.51871 6.86126L1.16331 11.3333L-1.80291e-06 10.1386L4.35458 5.66658L-5.44162e-07 1.19459L1.16331 -8.24388e-05L5.51789 4.47191C5.67095 4.62859 5.79233 4.8148 5.87507 5.01982C5.95781 5.22485 6.00027 5.44465 6 5.66658Z"
                  fill="#ffffff"
                />
              </svg>
              <svg className="lg:block hidden" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.5 5C4.3238 5.00031 4.14929 4.96504 3.98647 4.89621C3.82366 4.82738 3.67577 4.72635 3.55129 4.59893L0 0.969423L0.94871 0L4.5 3.62882L8.05129 0L9 0.969423L5.44871 4.59824C5.32429 4.72579 5.17642 4.82695 5.0136 4.89589C4.85079 4.96484 4.67624 5.00022 4.5 5Z"
                  fill="white"
                />
              </svg>
            </div>
          )
        })}
      </div>

      <div className="flex lg:hidden lg:flex-row flex-col gap-8 lg:gap-32">
        {header.dynamicmenu?.map((l, index: number) => (
          <LosseLink
            key={index}
            className="relative font-hh-heading cursor-pointer uppercase text-[22px] lg:text-base flex items-center gap-1 font-bold text-white py-1 px-4 rounded-3xl hover:bg-hh-blue button-base-ring focus:ring-offset-black focus:ring-hh-blue"
            to={l?.link?.url}
          >
            {losseContentParse(l?.link?.title)}
          </LosseLink>
        ))}
      </div>

      <AnimatePresence key="menu">
        {menu.current !== -1 && (
          <motion.div
            key="DynamicMenuWrapper"
            initial={{ y: '-100%', pointerEvents: 'none' }}
            animate={{ y: 0, pointerEvents: 'auto' }}
            exit={{ y: '-100%', pointerEvents: 'none' }}
            navigation={{ duration: 0.3 }}
            className="absolute flex inset-x-0 top-0 z-[60] h-screen bg-white"
          >
            <div className="containerize flex h-full w-full bg-[#EAEAEA]">
              <HeaderDynamicLoader setMenu={setMenu} menu={menu} dynamicMenu={dynamicMenu} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export type DynamicMenuEdges = { node: Market | Service | Product }[]

function HeaderDynamicLoader({
  setMenu,
  menu,
  dynamicMenu
}: {
  setMenu: (menu: Menu) => void
  menu: Menu
  dynamicMenu: Maybe<Component_Header_Dynamicmenu> | undefined
}) {
  const layout = useLosseLayout()
  const [data, state] = useLosseData<{ items: { edges: DynamicMenuEdges }; categories?: { edges: Category } }>('DynamicMenu', {
    type: dynamicMenu?.style
  })
  const parentItems = data?.items?.edges?.filter((f) => f?.node?.parentId === null)

  if (data?.categories?.edges) {
    // @ts-ignore
    data.categories.edges.forEach((item) => {
      item.node.uri = `${dynamicMenu?.link?.url}?_f=${item?.node?.databaseId}`
    })
  }

  return (
    <>
      <div
        role="button"
        aria-label="Back"
        onClick={() => {
          setMenu({ ...menu, current: -1 })
        }}
        className={clsx(
          menu.current === -1 && 'hidden',
          menu.current !== -1 && 'block absolute z-[40] lg:hidden lg:z-30 md:left-10 left-5 top-11',
          dynamicMenu?.style === 'products' && 'text-hh-mine-shaft',
          dynamicMenu?.style !== 'products' && 'text-white'
        )}
      >
        <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.93317e-06 8.5C-0.000560372 8.16719 0.0629276 7.83754 0.18682 7.53C0.310713 7.22247 0.492566 6.94311 0.721929 6.70799L7.25504 -7.62748e-08L9 1.79201L2.46812 8.5L9 15.208L7.25504 17L0.723163 10.292C0.493578 10.057 0.311497 9.77768 0.187391 9.47014C0.0632847 9.1626 -0.000398578 8.8329 3.93317e-06 8.5Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <div
        role="button"
        onClick={() => setMenu({ ...menu, current: -1 })}
        aria-label="Category"
        className={
          menu.current === -1
            ? `hidden`
            : `block lg:hidden absolute z-[40] lg:z-30 text-center right-0 left-0 mx-auto top-9 text-lg font-bold uppercase font-hh-heading text-[#888888]`
        }
      >
        {losseContentParse(dynamicMenu?.link?.title)}
      </div>

      <div
        className="absolute z-[40] lg:z-30 right-5 md:right-10 top-10 lg:top-5 text-center lg:block hidden"
        role="button"
        onClick={() => {
          setMenu({ submenu: 0, current: -1 })
          layout.setScrollable(true)
        }}
        aria-label="Close menu"
      >
        <svg className="lg:hidden block" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 1.17833L18.8217 0L10 8.82167L1.17833 0L0 1.17833L8.82167 10L0 18.8217L1.17833 20L10 11.1783L18.8217 20L20 18.8217L11.1783 10L20 1.17833Z"
            fill="white"
          />
        </svg>
        <div className="relative hidden lg:block ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[4px] before:rotate-45 before:bg-hh-blue before:[content:''] after:absolute after:h-[33px] after:w-[4px] after:-rotate-45 after:bg-hh-blue after:[content:''] hover:opacity-50" />
      </div>

      {state === 'loading' && (
        <div className="flex items-center justify-center p-24 w-full bg-hh-mine-shaft text-white lg:bg-hh-concrete lg:text-hh-mine-shaft">
          <Loading />
          <Message className="ml-3">Loading menu ...</Message>
        </div>
      )}
      {state === 'done' && (
        <>
          {/* PREVIEW */}
          <div className="bg-hh-mine-shaft lg:flex hidden relative w-[30%] pt-32 lg:pt-20 pb-10 px-5 md:px-10 lg:px-20 overflow-hidden flex-col justify-between">
            {dynamicMenu?.style === 'products' && (
              <>
                <div>
                  {dynamicMenu?.link ? <TitleLink {...dynamicMenu?.link} /> : <div className="">{dynamicMenu?.link?.title}</div>}
                  <DynamicMenu
                    menu={menu}
                    setMenu={setMenu}
                    items={data?.items?.edges}
                    filteredItems={data?.categories?.edges as unknown as DynamicMenuEdges}
                  />
                </div>
              </>
            )}
            {dynamicMenu?.style !== 'products' && <DynamicPreview menu={menu} items={parentItems} />}
          </div>
          {/* MENU */}
          <div
            className={clsx(
              dynamicMenu?.style === 'products' && 'lg:bg-white',
              dynamicMenu?.style !== 'products' && 'bg-hh-mine-shaft',
              'w-full h-full lg:w-[30%] pt-32 lg:pt-20 pb-10 px-5 md:px-10 xl:px-20 overflow-y-auto flex flex-col justify-between'
            )}
          >
            <div>
              {dynamicMenu?.style !== 'products' && (
                <>{dynamicMenu?.link ? <TitleLink {...dynamicMenu?.link} /> : <div className="">{dynamicMenu?.link?.title}</div>}</>
              )}
              <div className="lg:pl-3">
                {dynamicMenu?.style === 'products' && (
                  <>
                    <TitleLink
                      color="dark"
                      // @ts-ignore
                      title={data?.categories?.edges[menu.submenu]?.node.title as string}
                      // @ts-ignore
                      url={`${dynamicMenu?.link?.url}?_f=${data?.categories?.edges[menu.submenu]?.node.databaseId}`}
                    />
                    <DynamicSubmenuProducts
                      menu={menu}
                      filteredItems={data?.items?.edges?.filter((f) => {
                        const { node: item } = f as { node: Product }

                        if (!item || item?.categories?.nodes?.length === 0) {
                          return false
                        }

                        return !!(
                          menu.submenu >= 0 &&
                          // @ts-ignore
                          item?.categories?.edges.find(({ node: { id } }) => id === data?.categories?.edges[menu.submenu]?.node.id)
                        )
                      })}
                    />
                  </>
                )}
                {dynamicMenu?.style !== 'products' && (
                  <DynamicMenu menu={menu} setMenu={setMenu} items={data?.items?.edges} filteredItems={parentItems} />
                )}
              </div>
            </div>
            {dynamicMenu?.style === 'markets' && (
              <div className="mt-10">
                <SearchCatalog />
              </div>
            )}
          </div>
          {/* SUBMENU */}
          <div
            className={clsx(
              (dynamicMenu?.style === 'products' || dynamicMenu?.style === 'services') && 'lg:bg-concrete',
              dynamicMenu?.style !== 'products' && dynamicMenu?.style !== 'services' && 'lg:bg-white',
              'py-20 lg:block hidden w-[40%] px-10 xl:px-14 overflow-y-auto'
            )}
          >
            {(dynamicMenu?.style === 'products' || dynamicMenu?.style === 'services') && <SearchCatalog dark />}
            {dynamicMenu?.style === 'markets' && parentItems && (
              <TitleLink color="dark" title={parentItems[menu.submenu]?.node.title} url={parentItems[menu.submenu]?.node.uri} />
            )}
            <DynamicSubmenu menu={menu} items={data?.items?.edges} filteredItems={parentItems} />
          </div>
        </>
      )}
    </>
  )
}

interface TitleLinkProps extends Acf_Link {
  color?: 'dark' | 'light'
}

function TitleLink(link: TitleLinkProps) {
  return (
    <LosseLink
      to={link.url}
      target={link.target as string}
      className={clsx((!link.color || link.color === 'light') && 'text-white', 'lg:block hidden pb-10 font-bold group')}
    >
      <span className="text-2xl xl:text-3xl uppercase font-hh-heading block">{losseContentParse(link.title)}</span>
      <span className="flex items-center font-hh-form font-light text-sm mt-1 group-hover:underline">
        Go to {losseContentParse(link.title?.toLocaleLowerCase())}
        <svg className="w-2 h-2 ml-1" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 10C12.0007 10.3915 11.9161 10.7794 11.7509 11.1412C11.5857 11.503 11.3432 11.8316 11.0374 12.1082L2.32661 20L-1.07969e-07 17.8918L8.70917 10L-9.16285e-07 2.10825L2.32661 -8.68032e-08L11.0358 7.89176C11.3419 8.16825 11.5847 8.49685 11.7501 8.85866C11.9156 9.22047 12.0005 9.60835 12 10Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </LosseLink>
  )
}
