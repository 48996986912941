import { useState } from 'react'
import type { Page_Flexcontent_Flex_Locations, Location } from '~/graphql/types'
import Region from './Region'
import Searchable from './Searchable'

interface LocationsProps {
  fields: Page_Flexcontent_Flex_Locations
  search: boolean
  locations: Location[]
}

export default function Locations({ fields, search, locations }: LocationsProps) {
  const [isSearch, setIsSearch] = useState<boolean>(search)

  if (locations === undefined) {
    locations = fields.locations?.nodes as Location[]
  }

  if (isSearch) {
    return <Searchable readMore={fields.readMore} filterWords={fields.filterWords} searchbar={fields.searchbar} locations={locations} />
  }

  console.log(locations);


  return (
    <Region
      readMore={fields.readMore}
      findLocationLabel={fields.findLocationLabel}
      selectionLabel={fields.selectionLabel}
      onSearchButtonClick={() => setIsSearch(true)}
      title={fields.title || ''}
      locations={locations}
    />
  )
}

export function mapToMarkers(locations: Location[], searchParams: URLSearchParams) {
  const selected = searchParams.get('selected')

  return (
    locations.flatMap((location) => {
      if (!location) return []
      const locationNode = location as Location
      if (!locationNode.recap) return []
      if (!locationNode.title) return []
      if (!locationNode.recap.lat) return []
      if (!locationNode.recap.lng) return []

      return {
        id: locationNode.id,
        label: locationNode.title,
        hovered: false,
        active: locationNode.id === selected || (!selected && isHq(location)),
        lat: parseInt(locationNode.recap.lat, 10),
        lng: parseInt(locationNode.recap.lng, 10),
        recap: location.recap
      }
    }) || []
  )
}

export function isHq(location: Location) {
  return location?.recap?.lat === '52.2301476' && location?.recap?.lng === '5.3738704'
}
