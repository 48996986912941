import type { DynamicMenuEdges } from './HeaderDynamic'
import { LossePlaatjie, LosseLink, losseContentParse } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
interface DynamicSubmenuProps {
  items?: DynamicMenuEdges
  filteredItems?: DynamicMenuEdges
  takeFilteredItems?: boolean
  menu: {
    current: number
    submenu: number
  }
}

export default function DynamicSubmenu({ items, filteredItems, menu }: DynamicSubmenuProps) {
  if (!filteredItems || Object.keys(filteredItems).length === 0 || !filteredItems[menu.submenu]) {
    return null
  }

  const parentId = filteredItems[menu.submenu].node.id

  if (!parentId) {
    return null
  }

  const submenuItems = items?.filter(({ node }) => node.parentId === parentId)

  if (!submenuItems || submenuItems.length === 0) {
    return null
  }

  const hasImage = typeof submenuItems[0]?.node?.recap?.image !== 'undefined'

  return (
    <div className={`grid grid-cols-4 lg:grid-cols-2 ${hasImage ? `gap-4 lg:gap-12` : `gap-2 lg:gap-8`}`}>
      <AnimatePresence exitBeforeEnter>
        {submenuItems?.map((f, index) => {
          return (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={hasImage ? { duration: 0.3, delay: index * 0.1 } : { duration: 0.3 }}
              className={`${hasImage ? `col-span-2 sm:col-span-1` : `pl-4 first:pt-4 lg:p-0 col-span-4 lg:col-span-2`} group`}
              key={`dynamic-submenu-${menu.submenu}-${index}`}
              id={`dynamic-submenu-${menu.submenu}-${index}`}
            >
              <LosseLink to={f?.node?.uri}>
                {f?.node?.recap?.image && (
                  <LossePlaatjie
                    className="w-full aspect-square object-cover"
                    maxwidth={500}
                    src={f?.node?.recap?.image}
                    alt="submenu-image"
                  />
                )}
                <div
                  className={`group-hover:underline text-white lg:text-black lg:text-base ${
                    hasImage
                      ? `pt-3 text-center font-bold uppercase text-sm font-hh-heading`
                      : `text-left text-lg font-light normal-case font-hh-form`
                  }`}
                >
                  {losseContentParse(f?.node?.title)}
                </div>
              </LosseLink>
            </motion.div>
          )
        })}
      </AnimatePresence>
    </div>
  )
}
